import type { GetServerSidePropsContext } from 'next';
import { getCsrfToken, getProviders, getSession, signIn } from 'next-auth/react';
import Image from 'next/image';

function SignIn() {
  return (
    <>
      <main className="flex flex-col md:flex-row">
        <div className="flex flex-col justify-center bg-gray-50 md:w-1/2">
          <div className="flex flex-col justify-center md:justify-start md:pt-0 md:px-24 lg:px-32">
            <p className="text-2xl text-center">Connectez-vous à votre compte</p>
            <div className="flex flex-col pt-3 md:pt-8">
              <button
                onClick={() => void signIn('auth0')}
                type="submit"
                className="w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#149B93] shadow-md hover:text-white hover:bg-[#14532d] focus:outline-none focus:ring-2"
              >
                <span className="w-full text-">Connectez-vous avec vos identifiants</span>
              </button>
            </div>
            <div className="flex justify-center items-center pt-3 md:pt-8">
              <span className="w-full border border-black"></span>
              <span className="px-4">Ou</span>
              <span className="w-full border border-black"></span>
            </div>
            <div className="flex flex-col pt-3 md:pt-8">
              <button
                onClick={() => void signIn('azure-ad')}
                type="submit"
                className="w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#149B93] shadow-md hover:text-white hover:bg-[#14532d] focus:outline-none focus:ring-2"
              >
                <span className="w-full text-">Connectez-vous avec votre compte Microsoft</span>
              </button>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 pt-4">
          <Image
            src="/undraw_data_trends.svg"
            alt="Login image"
            width={1200}
            height={800}
            className="object-center object-cover"
          />
        </div>
      </main>
    </>
  );
}

export default SignIn;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req } = context;
  const session = await getSession({ req });

  if (session) {
    return {
      redirect: { destination: '/kpi-builder/akto' },
    };
  }

  return {
    props: {
      providers: await getProviders(),
      csrfToken: await getCsrfToken(context),
    },
  };
}
